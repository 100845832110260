import type { ContractUserGeneratedEndorsementStatus } from '@orus.eu/backend/src/services/contract-endorsement/contract-endorsement-store'
import { formatDdMmYyyy, type CalendarDate } from '@orus.eu/calendar-date'
import { type AbstractDimension, type PartialDimensionnedState } from '@orus.eu/dimensions'
import { Button, colorTokens, Text, typedMemo } from '@orus.eu/pharaoh'
import { ButtonGroup } from '@orus.eu/pharaoh/src/components/button/button-group'
import { Row, RowContainer } from '@orus.eu/pharaoh/src/components/rows'
import { Dropdown } from '@orus.eu/pharaoh/src/patterns/dropdown'
import { useCallback } from 'react'
import { getHubspotContactLink } from '../../../../../lib/hubspot-util'
import { openLinkInNewWindow } from '../../../../../lib/window-util'
import { useBuildUrl } from '../../../../../use-build-url'

export type PlatformPendingEndorsementPageButtonBarProps<Dimensions extends readonly AbstractDimension[]> = {
  subscriptionId: string
  changes: PartialDimensionnedState<Dimensions>
  creationDate: CalendarDate
  onReset: () => void
  onValidate: () => void
  currentEmail: string | undefined
  nextEnabledByAllContributions: boolean
  bypassBrowserConfirmationDialog: () => void
  status: ContractUserGeneratedEndorsementStatus
}

export const PlatformPendingEndorsementPageButtonBar = typedMemo(function PlatformPendingEndorsementPageButtonBar<
  Dimensions extends readonly AbstractDimension[],
>(props: PlatformPendingEndorsementPageButtonBarProps<Dimensions>) {
  const { buildUrl } = useBuildUrl()
  const hasChanges = Object.keys(props.changes).length > 0

  const { currentEmail, creationDate } = props

  const openHubspotContact = useCallback(() => {
    if (!currentEmail) {
      alert(
        "Il n'y a pas d'email associé à ce devis. Cette fonctionnalité n'est " +
          "disponible que lorsqu'un email a été saisi",
      )
      return
    }
    const url = getHubspotContactLink(currentEmail)
    openLinkInNewWindow(url)
  }, [currentEmail])

  return (
    <>
      <Text variant="body2" color={colorTokens['color-text-base-basic']}>
        Créé le {formatDdMmYyyy(creationDate)}
      </Text>
      <ButtonGroup>
        <Button title="Hubspot" variant="secondary" size="small" onClick={openHubspotContact} icon="hubspot-brands" />
        <Dropdown trigger={<Button variant="secondary" size="small" icon="ellipsis-regular" />}>
          <RowContainer variant="border" size="small">
            <Row
              subtitle="Voir le contrat"
              leftIcon="file-lines-light"
              key="1"
              href={buildUrl({
                to: '/bak/contracts/$subscriptionId',
                params: { subscriptionId: props.subscriptionId },
              })}
            />
            <Row
              subtitle="Historique du devis"
              leftIcon="list-ul-light"
              key="2"
              href={buildUrl({
                to: '/bak/pending-subscriptions/$subscriptionId/history',
                params: { subscriptionId: props.subscriptionId },
              })}
            />
            <Row
              subtitle="Annuler tous les changements"
              leftIcon="arrow-rotate-right-light"
              disabled={!hasChanges}
              onClick={props.onReset}
              key="3"
            />
          </RowContainer>
        </Dropdown>

        {props.status === 'open' ? (
          <Button
            variant="primary"
            size="small"
            disabled={!hasChanges || !props.nextEnabledByAllContributions}
            onClick={props.onValidate}
          >
            Sauvegarder
          </Button>
        ) : null}
      </ButtonGroup>
    </>
  )
})
