import { css } from '@emotion/react'
import type { CalendarDate } from '@orus.eu/calendar-date'
import type { AbstractDimension, PartialDimensionnedState } from '@orus.eu/dimensions'
import { Badge, Button, typedMemo } from '@orus.eu/pharaoh'
import { Header } from '@orus.eu/pharaoh/src/patterns/header'
import { useParams } from '@tanstack/react-router'
import { useMemo } from 'react'
import { trpcReact } from '../../../../../client'
import { useNavigateTo } from '../../../../../lib/hooks/use-navigate-to-route'
import { LocalLoadingState } from '../../../../molecules/local-loading-state'
import { PlatformPendingEndorsementPageButtonBar } from './PlatformPendingEndorsementPageButtonBar'

export type PlatformPendingEndorsementPageHeaderProps<Dimensions extends readonly AbstractDimension[]> = {
  subscriptionId: string
  changes: PartialDimensionnedState<Dimensions>
  hasQuote: boolean
  onReset: () => void
  onValidate: () => void
  currentEmail: string | undefined
  currentPhone: string | undefined
  nextEnabledByAllContributions: boolean
  bypassBrowserConfirmationDialog: () => void
  creationDate: CalendarDate
}

export const PlatformPendingEndorsementPageHeader = typedMemo(function PlatformPendingEndorsementPageHeader<
  Dimensions extends readonly AbstractDimension[],
>(props: PlatformPendingEndorsementPageHeaderProps<Dimensions>) {
  const { endorsementId } = useParams({
    from: '/bak/contracts/$subscriptionId/endorsement/$endorsementId',
  })
  const navigateToBackofficeHome = useNavigateTo({ to: '/bak/home' })

  const { data } = trpcReact.endorsement.getEndorsementStatus.useQuery({ endorsementId })

  const badgeText = useMemo(() => {
    if (!data) return null
    switch (data) {
      case 'approved':
        return 'Signé'
      case 'open':
        return 'En cours'
      case 'cancelled':
        return 'Annulé'
      case 'expired':
        return 'Expiré'
    }
  }, [data])

  if (!data) return <LocalLoadingState />

  return (
    <Header
      css={css`
        /* hide while loading, but using visibility and non empty text to avoid ui flickering */
        visibility: ${props.creationDate ? undefined : 'hidden'};
      `}
      title={'Avenant'}
      badge={badgeText ? <Badge variant="blue">{badgeText}</Badge> : null}
      leftButton={
        <Button icon="arrow-left-regular" size="small" variant="secondary" onClick={navigateToBackofficeHome} />
      }
      rightButtonGroups={[
        <PlatformPendingEndorsementPageButtonBar
          subscriptionId={props.subscriptionId}
          changes={props.changes}
          onReset={props.onReset}
          onValidate={props.onValidate}
          creationDate={props.creationDate}
          currentEmail={props.currentEmail}
          nextEnabledByAllContributions={props.nextEnabledByAllContributions}
          bypassBrowserConfirmationDialog={props.bypassBrowserConfirmationDialog}
          key="1"
          status={data}
        />,
      ]}
    />
  )
})
